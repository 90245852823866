import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/models/user.model';
import { AppState } from 'app/store/app.reducer';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import * as actions from 'app/store/actions';

@Injectable({ providedIn: 'root' })
export class AuthService {
  // private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private store: Store<AppState>
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  get currentUser(): any {
    let currentUser = '';
    this.store.select('auth').subscribe((auth) => {
      currentUser = auth.currentUser;
    });
    return currentUser;
  }

  get authenticated(): boolean {
    let authenticated = false;
    this.store.select('auth').subscribe((auth) => {
      if (auth.currentUser !== null) {
        authenticated = true;
      } else {
        authenticated = false;
      }
    });

    return authenticated;
  }

  get accessToken(): string {
    let accessToken = '';
    this.store.select('auth').subscribe((auth) => {
      accessToken = auth.currentUser?.token;
    });
    return accessToken;
  }

  get role(): string {
    let role = '';
    this.store.select('auth').subscribe((auth) => {
      role = auth.currentUser?.role;
    });
    return role;
  }

  get headers_http() {
    let header = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
    };
    return { headers: header };
  }

  get headers_http_TextPlain() {
    let header = {
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${this.accessToken}`,
    };
    return { headers: header };
  }

  get headers_http_FormData() {
    let header = {
      // 'Content-Type': 'multipart/form-data',
      // Enviar como formData
      Authorization: `Bearer ${this.accessToken}`,
    };
    return { headers: header };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(username: string, password: string): Observable<any> {
    // Throw error, if the user is already logged in
    // if ( this.authenticated )
    // {
    //     return throwError('User is already logged in.');
    // }

    // return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    //     switchMap((response: any) =>
    //     {
    //         // Store the access token in the local storage
    //         this.accessToken = response.accessToken;

    //         // Set the authenticated flag to true
    //         this._authenticated = true;

    //         // Store the user on the user service
    //         this._userService.user = response.user;

    //         // Return a new observable with the response
    //         return of(response);
    //     }),
    // );
    const url = `${environment.url}/login`;
    const header = {
      Authorization: 'Basic ' + btoa(username + ':' + password),
    };
    // El campo null es el body de la petición. En este caso no se envía nada en el body
    return this._httpClient.post<User>(url, null, { headers: header }).pipe(
      switchMap((response: any) => {
        this.store.dispatch(new actions.SetUserAction(response));

        console.log('response', response);
        //  localStorage.setItem('user', JSON.stringify(response));

        // Set the authenticated flag to true
        //this._authenticated = true;

        // Store the access token in the local storage
        //this.accessToken = response.token;

        // // Store the user on the user service
        //this._userService.user = response;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          if (response.accessToken) {
            //this.accessToken = response.accessToken;
          }
          this.store.dispatch(new actions.SetUserAction(response));

          // Set the authenticated flag to true
          //this._authenticated = true;

          // Store the user on the user service
          //this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this.store.dispatch(new actions.UnsetUserAction());

    // Remove the access token from the local storage
    //localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    //this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated == true) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
