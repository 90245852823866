import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'environments/environment';

import { Observable } from 'rxjs';
import { PauService } from './pau.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const pauInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const pauService = inject(PauService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    let urlApi = environment.url

    //si es un get y la url es de  la api agrego parametro PAU
    //if(newReq.method ==='GET' && newReq.url.substring(0,urlApi.length) === urlApi){
    if (newReq.url.substring(0, urlApi.length) === urlApi) {
        let newUrl;
        const includesParams = req.url.includes("?");


        if (includesParams) {

            newUrl = req.url + '&pau=' + pauService.getPauNumber() + '&descripcion_pau=' + pauService.getPauDescription()
        } else {
            newUrl = req.url + '?pau=' + pauService.getPauNumber() + '&descripcion_pau=' + pauService.getPauDescription()
        }

        newReq = req.clone({
            url: newUrl,

        });
    }
    // Response
    return next(newReq)

};
