import * as fromRejections from '../actions/rejections.actions';


export interface RejectionsState {
    filters: any;
    rejectionList: any[];
    selectedRejectionId: any;
    selectedRejectionData: any;
    rejectionStatusSummay: any[];
}

const initialState: RejectionsState = {
    filters: null,
    rejectionList: [],
    selectedRejectionId: null,
    selectedRejectionData: null,
    rejectionStatusSummay: null

};


export function rejectionReducer(state = initialState, action: fromRejections.rejectionsActions): RejectionsState {
    switch (action.type) {

        case fromRejections.SET_REJECTION_FILTERS:
            return {
                ...state,
                filters: { ...action.payload }
            };
        case fromRejections.UNSET_REJECTION_FILTERS:
            return {
                ...state,
                filters: null
            };

        case fromRejections.SET_REJECTION_LIST:
            return {
                ...state,
                rejectionList: [...action.payload]
            };

        case fromRejections.UNSET_REJECTION_LIST:
            return {
                ...state,
                rejectionList: []
            };
        case fromRejections.SET_SELECTED_REJECTION:
            return {
                ...state,
                selectedRejectionId: action.payload
            };

        case fromRejections.UNSET_SELECTED_REJECTION:
            return {
                ...state,
                selectedRejectionId: null
            };

        case fromRejections.SET_SELECTED_REJECTION_DATA:
            return {
                ...state,
                selectedRejectionData: { ...action.payload }
            };

        case fromRejections.UNSET_SELECTED_REJECTION_DATA:
            return {
                ...state,
                selectedRejectionData: null
            };

        default:
            return state;
    }
}